@import '../../GlobalVars.scss';
@import '../Layout/index.scss';

.about-page {
    display: grid;
    // height: 100%;
    grid-template-rows: 75px auto;
    // grid-template-columns: 40% 60%;
    padding: 15px 0px 15px 15px;

    .toptext-zone { 
        grid-row-start: 1;
        grid-row-end: 2;
        // grid-column-start: 1;
        // grid-column-end: 3;
        justify-content: center;
        text-align: center;

        h1 {
            color: $text-color;
            font-size: 26px;
            font-style: italic;
            font-weight: 125;
        }
    }

    .flex-zone-wrapper {
        grid-row-start: 2;
        grid-row-end: 3;
    }

    .flex-zone {
        display: flex;
        flex-wrap: no-wrap;
    }

    .picture-zone {
        // display: grid;
        // grid-row-start: 2;
        // grid-row-end: 3;
        // grid-column-start: 1;
        // grid-column-end: 2;
        display: flex;
        margin: 15px;
        width: 100%;

        img {
            width: 100%;
        }
    }

    .text-zone {
        display: flex;
        margin: 0px 15px;
        font-size: 16px;
        line-height: 24px;
    }
}

@media (max-width: 1100px) {
    .flex-zone {
        flex-wrap: wrap;
    }
    .text-zone {
        font-size: 11px;
    }
}
