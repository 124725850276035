@import '../../GlobalVars.scss';

// pixel values where upon which masonry grid condenses (values from Columns const)
$threeRows: 1400px;
$twoRows: 1200px;
$oneRow: 900px;

.home-page {
    padding: 15px;
}

.masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    // width: 91.5%;
}

.masonry-grid-column {
    background-clip: padding-box;
    margin-left: .5vw;
    margin-right: -2.5vw;
    margin-bottom: .36vw;
    margin-top: .36vw;

    &:hover {
        opacity: .7;
    }
    
    img {
        cursor: zoom-in;
    }
}

// adjust gutter size based on window size

@media (max-width: $threeRows) {
    .masonry-grid-column {
        margin-left: .5vw;
        margin-right: -3vw;
        margin-bottom: .55vw;
        margin-top: .55vw;
    }
}

@media (max-width: $twoRows) {
    .masonry-grid-column {
        margin-left: .5vw;
        margin-right: -4.5vw;
        margin-bottom: .5vw;
        margin-top: .5vw;
    }
}

.image-viewer-background {
    display: flex;
    flex-wrap: wrap;

    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1;

    background-color: $background-color;

    cursor: zoom-out;
}

.fullsize-image {
    justify-content: center;

    max-height: 92%;
    max-width: 92%;
    
    margin: 0 auto;
    padding-top: 15px;
    z-index: 1;

    animation: fadeIn .7s forwards;

    cursor: default;
}

.viewer-textzone {
    display: flex;
    width: 100%;
    justify-content: center;
    bottom: 2vh;
    z-index: 2;

    text-align: center;
    white-space: nowrap; //TODO
    font-size: 1.5em;
    font-style: italic;
    font-weight: 500;

    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: $text-color;

    cursor: default;
}

.divider {
    font-style: normal;
}