@import '../../GlobalVars.scss';

.page {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $background-color;
}

.container {
    will-change: contents;
    height: 100%;
    left: 0px;
    right: 100px;
    // min-height: 566px;
    position: absolute;
    opacity: 0;
    background-color: $background-color;
    // margin: 0 auto;
    z-index: 1;
    // transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: .5s;
}

@media (max-width: 500px) {
    .container {
        right: 75px;
    }
}