html {
  font-size: 50%;
}

body {
  margin: 0;
  font-family: 'EB Garamond', serif;
  background-color: #EEE; /* needs to be manually updated with $background-color*/
  overflow-y: scroll;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
