@import '../../GlobalVars.scss';

.nav-bar {
    width: 100px;
    height: 100%;
    position: fixed;
    right: 0px;

    display: grid;
    grid-template-rows: 120px 240px 60px;
    background: $background-color;
    z-index: 3;
    min-height: 300px;

    .logo {
        display: grid;
        grid-row-start: 1;
        grid-row-end: 2;
        padding: 20px 0;

        img {
            margin: 2px auto;
            // width: 100px;
            height: 75px;
        }
    }

    nav {
        display: grid;
        grid-row-start: 2;
        grid-row-end: 3;

        text-align: center;
        position: absolute;
        // height: 200px;
        // top: 125px;
        width: 100%;

        a {
            font-size: 15px;
            line-height: 25px;
            height: 30px;
            position: relative;
            color: #444;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #000;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 14px;
                font-style: italic;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
            }


            &:first-child {
                &:after {
                    content: 'HOME';
                }
            }
        }

        a.about-link {
            &:after {
                content: 'ABOUT';
            }
        }

        a.contact-link {
            &:after {
                content: 'CONTACT';
            }
        }

        a.active {
            svg {
                color: #999;
            }
        }
    }

    ul.no-bullets {
        position: absolute;
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
        bottom: 20px;
        width: 100px;

        li {
            display: inline-block;

            a {
                filter: invert(.7);
                padding: 7px 3px;
                width: 36px;
                font-size: 22px;
                line-height: 24px;
                &:before{
                    text-rendering: optimizeLegibility;                    
                }

                &:hover {
                    opacity: .5;
                }
            }
        }
    }
}

// adjust sidebar size for smaller mobile displays
@media (max-width: 500px) {
    .nav-bar {
        width: 75px;

        ul.no-bullets {
            width: 75px;
            li {
                a {
                    padding: 2px 2px;
                    width: 50px;
                    font-size: 18px;
                    line-height: 17px;
                }
            }
        }
    }
}